import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import './App.css';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ModalForm from './components/ModalForm';
import GrowTransitionStars from './components/GrowTransitionStars';
import Alert from '@mui/material/Alert';

// Stripe public key
const stripePromise = loadStripe("your-publishable-key-here"); // Replace with your actual Stripe public key

function App() {
  const domainName = "crazylou.fr";
  const price = 2100;
  const fee = 0;
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);

  const handleCheckout = async () => {
    setLoading(true);
    // Show alert instead of proceeding with checkout
    setAlertVisible(true);
    setLoading(false);

    // Hide the alert after 3 seconds
    setTimeout(() => {
      setAlertVisible(false);
    }, 3000);
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <header className="App-header">
          <h1>Buy {domainName}</h1>
          <GrowTransitionStars />
          <Box component="section" sx={{ p: 3, width: '85%' }}>
            <p>Crazylou.fr est un domaine idéal pour lancer une boutique en ligne. Parfait pour une entreprise française ou etrangere souhaitant renforcer sa présence en ligne.</p>
          </Box>
          <Box sx={{ minWidth: 400 }}>
            <Card variant="outlined" sx={{ p: 3, m: 2, backgroundColor: "#364046", color: "white" }}>
              <Typography variant="body2">Transaction fee: {fee}</Typography>
              <p><strong>Price: €{price}</strong></p>
              <Button
                variant="contained"
                size="large"
                color="success"
                onClick={handleCheckout}
                disabled={loading}
              >
                {loading ? "Processing..." : "Buy now"}
              </Button>
              <ModalForm />
            </Card>
            {alertVisible && (
              <Alert severity="info" sx={{ m: 2 }}>
                Cette option n'est pas disponible, veuillez nous contacter.
              </Alert>
            )}
            <Typography variant="body2" sx={{ color: "#777" }}>
              {domainName} {new Date().getFullYear()} contact@crazylou.fr
            </Typography>
          </Box>
        </header>
      </div>
    </Elements>
  );
}

export default App;
