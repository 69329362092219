import React, { useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Textarea from '@mui/material/TextareaAutosize';
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  color: 'white',
  bgcolor: '#282f34',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalForm = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '', // Added field for textarea
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Handle form submission
    handleClose(); // Close modal after submission
  };

  return (
    <div>
      <Button 
        variant="outlined" 
        size="small"
        startIcon={<MailOutlineIcon />}
        color="success"
        onClick={handleOpen}
        sx={{ m: 2 }}
      >
        Contact us
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style} align='center'>
          <Typography id="modal-title" variant="h6" component="h2">
            Nous contacter
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nom"
              name="name"
              autoComplete="name"
              autoFocus
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
            />
            <Textarea 
              minRows={12}
              placeholder="Laissez nous un message"
              name="message" // Use the correct name for state binding
              value={formData.message} // Correct value binding
              onChange={handleChange}
              style={{
                width: '100%', // Make it full width
                borderRadius: '4px', // Optional: rounded corners
                padding: '8px', // Optional: padding for better appearance
                marginTop: '16px', // Space above
                backgroundColor: '#282f34', // Background color
              }}
            />
            <Button type="submit" variant="contained" color="success" sx={{ mt: 2 }}>
              Envoyer
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalForm;
